import { IonButton, IonIcon, IonText } from "@ionic/react";

import React from "react";

export const MailButtons = ({ text }: { text: string }) => {
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <IonButton
        slot="start"
        color="secondary"
        onClick={() => openInNewTab("https://sissolution.co.th:2096/")}
      >
        <IonIcon src="assets/mail-open.svg"></IonIcon>
      </IonButton>
      <IonText>{text}</IonText>
    </>
  );
};