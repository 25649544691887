export const serviceData = [
  {
    id: "1",
    title: "Project Reference 2019",
    subTittle:
      "Project: OTP Central Clearing House (CCH) Relocation from CS Loxinfo to MRTA",
    content: "Customer: MRTA (Mass Rapid Transit Authority of Thailand)",
    imge: "assets/images/services/job5.jpg",
  },
  {
    id: "2",
    title: "Project Reference 2019",
    subTittle:
      "Project: Acceptance of Blue Line Extension Phase 1 and 2 Pilot Test",
    content: "Customer: VIX Technology (Bangkok) Co., Ltd.",
    imge: "assets/images/services/job3.jpg",
  },
  {
    id: "3",
    title: "Project Reference 2019",
    subTittle:
      "Project: Replacement and Install Gate PID (Passenger Information Display) New Model",
    content: "Customer: ARL (Airport Rail Link)",
    imge: "assets/images/services/job6.jpg",
  },
  {
    id: "4",
    title: "Project Reference 2019",
    subTittle: "Project: Coin Box for TVM (Ticket Vending Machine)",
    content: "Customer: Airport Rail Link",
    imge: "assets/images/services/job7.jpg",
  },
  {
    id: "5",
    title: "Project Reference 2019",
    subTittle:
      "Project: Installation Rabbit Reader for Salaya Bus and RTC Nonthaburi City Bus",
    content: "Customer: Rabbit Card",
    imge: "assets/images/services/job13.jpg",
  },
  {
    id: "6",
    title: "Project Reference 2019",
    subTittle: "Project: Installation Rabbit LinePay Reader for BigC ",
    content: "Customer: Rabbit LinePay",
    imge: "assets/images/services/job10.jpg",
  },
  {
    id: "7",
    title: "Project Reference 2019",
    subTittle:
      "Project: Coin Parameter เหรียญกษาปณ์ รัชกาลที่ 10 for TVM (Ticket Vending Machine) Coin Acceptor",
    content:
      "Customer: BEM (Bangkok Expressway and Metro Public Company Limited",
    imge: "assets/images/services/job2.jpg",
  },
  {
    id: "8",
    title: "Project Reference 2019",
    subTittle: "Project: Pinch Force Measurement System for Rail Doors",
    content:
      "Customer: BEM (Bangkok Expressway and Metro Public Company Limited) @MRT Purple Line ",
    imge: "assets/images/services/job12.jpg",
  },
  {
    id: "9",
    title: "Project Reference 2020",
    subTittle: "Project: AFC Report Development for MRT Purple Line",
    content: "Customer: VIX Technology (Bangkok) Co., Ltd.",
    imge: "assets/images/services/job4.jpg",
  },
  {
    id: "10",
    title: "Project Reference 2019-Present",
    subTittle: "Project: AFC Spare part and Tools",
    content: "Customer: BEM , BTS and ARL",
    imge: "assets/images/services/job1.jpg",
  },
];