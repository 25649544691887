import "./About.css";

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
} from "@ionic/react";

import { Footers } from "../components/Footers";
import { MailButtons } from "../components/MailButtons";
import { NavButtons } from "../components/NavButtons";
import React from "react";
import { aboutData } from "../data/abouts";

const About: React.FC = () => {
  return (
    <IonPage>
      {/* Header */}
      <IonHeader>
        <IonToolbar color="secondary">
          <MailButtons text="About" />
          <IonButtons slot="end">
            <NavButtons />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonText color="primary">
          <h1>SIAM INFINITY SOLUTION COMPANY</h1>
        </IonText>
        <IonGrid>
          <IonCol>
            <IonRow>
              <IonText>
                <div>
                  <h2>Team Specialist</h2>
                  <p>
                    Expertise in AFC (Auto Fare Collection) for the
                    Transportation in Thailand
                  </p>
                </div>
              </IonText>
            </IonRow>
          </IonCol>

          {aboutData.map((item, index) => (
            <IonRow>
              {/* <IonImg src={item.imge} alt="" class="Img"></IonImg> */}
              <IonText color="black">
                <p> &#8226; {item.content}</p>
              </IonText>
            </IonRow>

            // <IonCard key={index}>
            //   <IonRow>
            //     {/* <IonCol>
            //         <IonImg src={item.imge} alt="" class="logo"></IonImg>
            //     </IonCol> */}
            //     {item.content}
            //   </IonRow>
            // </IonCard>
          ))}
        </IonGrid>

        {/* <br />
        <IonText className="contentTitle">
          SIAM INFINITY SOLUTION COMPANY
        </IonText>
        <IonGrid>
          <IonCol>
            <IonRow>
              <IonText color="black">Team Specialist</IonText>
            </IonRow>
            <IonRow>
              <IonText color="black">
                <p>
                  {" "}
                  &#8226; Expertise in AFC (Auto Fare Collection) for the
                  Transportation in Thailand 5 Years
                </p>
              </IonText>
            </IonRow>
            <IonRow>
              <IonText color="black">
                <p>
                  {" "}
                  &#8226; Experience for managing the Largest CCH (Central
                  Clearing House) for Contact-less Stored Value Smart Card in
                  Thailand 5 Years
                </p>
              </IonText>
            </IonRow>
            <IonRow>
              <IonText color="black">
                <p> &#8226;Experience in Toll Collection System 7 Years</p>
              </IonText>
            </IonRow>
            <IonRow>
              <IonText color="black">
                <p>
                  {" "}
                  &#8226; Experience in Electronic Data Capture Machine
                  Retailers & Retail Merchants (NFC Card) 15 Years
                </p>
              </IonText>
            </IonRow>
            <IonRow>
              <IonText color="black">
                <p>
                  {" "}
                  &#8226; Experience in AFC (Auto Fare Collection) for Skytrain,
                  Subway, Airport Rail Link, Bus and Boat
                </p>
              </IonText>
            </IonRow>
          </IonCol>
        </IonGrid> */}
      </IonContent>

      {/* Footer */}
      <Footers />
    </IonPage>
  );
};

export default About;
