export const aboutData = [
  {
    id: "1",
    content:
      "5 Years experience for managing the Largest CCH (Central Clearing House) for Contact-less Stored Value Smart Card in Thailand",
    imge: "assets/images/abouts/id1.jpg",
  },
  {
    id: "2",
    content:
      "12 Years experience in AFC (Auto Fare Collection) for BTS Skytrain, MRT, Airport Rail Link",
    imge: "assets/images/abouts/id2.jpg",
  },
  {
    id: "3",
    content:
      "7 Years experience in Electronic Data Capture Machine Retailers & Retail Merchants (NFC Card) ",
    imge: "assets/images/abouts/id3.png",
  },
];
