import "./Home.css";

import {
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from "@ionic/react";

import { Footers } from "../components/Footers";
import { MailButtons } from "../components/MailButtons";
import { NavButtons } from "../components/NavButtons";
import React from "react";

const Home: React.FC = () => {
  return (
    <IonPage>
      {/* Header */}
      <IonHeader>
        <IonToolbar color="secondary">
          <MailButtons text="Home"/>
          <IonButtons slot="end">
            <NavButtons />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {/* Welcome */}
        <IonCard className="welcomeImage">
          <img src="assets/images/sis-big.jpg" alt="" />
          <IonCardHeader>
            <IonCardTitle>Welcome!</IonCardTitle>
            <IonCardSubtitle>SIAM INFINITY SOLUTION COMPANY </IonCardSubtitle>
          </IonCardHeader>
        </IonCard>
      </IonContent>

      {/* Footer */}
      <Footers />
    </IonPage>
  );
};

export default Home;
