import "./Contact.css";

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonTextarea,
  IonToolbar,
} from "@ionic/react";

import { Footers } from "../components/Footers";
import { MailButtons } from "../components/MailButtons";
import { NavButtons } from "../components/NavButtons";
import React from "react";

const Contact: React.FC = () => {
  return (
    <IonPage>
      {/* Header */}
      <IonHeader>
        <IonToolbar color="secondary">
          <MailButtons text="Contact" />
          <IonButtons slot="end">
            <NavButtons />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonGrid fixed={true}>
          <IonRow>
            <IonCol>
              <IonImg
                src="assets/images/sis-logo.jpg"
                alt=""
                className="Img"
              ></IonImg>
              <IonCard className="card-address">
                <IonText>
                  Address : Head Office 184/84 Forum Tower 17th Floor,
                  Ratchadapisek Road, Hui Khwang, Hui Khwang, Bangkok 10310
                  <br />
                  TEL: 089-924-3848
                </IonText>
              </IonCard>
              {/* <div text-center>
                <p>
                  Address : Head Office 184/84 Forum Tower 17th Floor,
                  Ratchadapisek Road, Hui Khwang, Hui Khwang, Bangkok 10310
                </p>
                <p>TEL : 089-924-3848</p>
              </div> */}
            </IonCol>
            <IonCol>
              <div text-center>
                <h3>Contact Us</h3>
                <p>Reach out to find out how we can help you</p>
              </div>
              <div>
                <br />
                <IonLabel>Name : </IonLabel>
                <IonItem>
                  <IonInput
                    name="fullname"
                    placeholder="fullname"
                    required
                  ></IonInput>
                </IonItem>
                <IonLabel>Email : </IonLabel>
                <IonItem>
                  <IonInput
                    name="email"
                    type="email"
                    placeholder="your@email.com"
                    required
                  ></IonInput>
                </IonItem>
                <IonLabel>Subject : </IonLabel>
                <IonItem>
                  <IonInput
                    name="subject"
                    placeholder="subject"
                    required
                  ></IonInput>
                </IonItem>
                <IonLabel>Message : </IonLabel>
                <IonItem>
                  <IonTextarea placeholder="Enter more information here..."></IonTextarea>
                </IonItem>
              </div>
              <br />
              <div>
                <IonButton size="large" expand="block">
                  Submit
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* <IonGrid>
          <IonRow>
            <IonCol>
              <IonImg
                src="assets/images/sis-logo.jpg"
                alt=""
                className="Img"
              ></IonImg>
              <br></br>
              <IonRow>
                <IonText>SIAM INFINITY SOLUTION COMPANY LIMITED</IonText>
              </IonRow>
              <br></br>
              <IonRow>
                <IonCol>Address : </IonCol>
                <IonCol>
                  {" "}
                  Head Office 184/84 ForumTower 17th Floor, Ratchadapisek Road,
                  Hui Khwang, Hui Khwang Bangkok 10310{" "}
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol></IonCol>
          </IonRow>
        </IonGrid> */}
      </IonContent>

      {/* Footer */}
      <Footers />
    </IonPage>
  );
};

export default Contact;
