import { IonFooter, IonTitle, IonToolbar } from "@ionic/react";

import React from "react";

export const Footers: React.FC = () => (
  <>
    <IonFooter>
      <IonToolbar color="medium">
        <IonTitle size="small">
           &copy; copyright 2021, SISSolution.co.th
        </IonTitle>
      </IonToolbar>
    </IonFooter>
  </>
);
