import "./Services.css";

import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { Footers } from "../components/Footers";
import { MailButtons } from "../components/MailButtons";
import { NavButtons } from "../components/NavButtons";
import React from "react";
import { serviceData } from "../data/services";

const Services: React.FC = () => {

  return (
    <IonPage>
      {/* Header */}
      <IonHeader>
        <IonToolbar color="secondary">
          <MailButtons text="Services" />
          <IonButtons slot="end">
            <NavButtons />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <br />
        <IonText className="contentTitle">SIS Project Reference</IonText>
        {/* Card Item */}
        <IonGrid fixed={true}>
          {serviceData.map((item, index) => (
            <IonCard key={index}>
              <IonRow>
                <IonCol>
                  <IonCardHeader>
                    <IonCardTitle className="cardTittle">
                      {item.title}
                    </IonCardTitle>
                    <IonCardSubtitle className="cardSubTittle">
                      {item.subTittle}
                    </IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent className="cardContent">
                    {item.content}
                  </IonCardContent>
                </IonCol>
                <IonCol>
                  <IonImg src={item.imge} alt="" className="Img"></IonImg>
                </IonCol>
              </IonRow>
            </IonCard>
          ))}
        </IonGrid>
      </IonContent>

      {/* Footer */}
      <Footers />
    </IonPage>
  );
};

export default Services;
